// reactapp/first-app/src/Ad Folder/Ad5.js
import React, { useEffect } from 'react';
import './Ad5.css';

const Ad5 = () => {
  const isDevelopment = process.env.NODE_ENV === 'development';

  // Adds ad functionality when the app is not in development mode
  useEffect(() => {
    if (!isDevelopment) {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (error) {
        console.error('AdSense error:', error);
      }
    }
  }, [isDevelopment]);

  return (
    <div className="ad-container-5">
      {!isDevelopment ? (
        <ins
          className="adsbygoogle-5"
          style={{ display: 'inline-block' }}
          data-ad-client="ca-pub-8102084468796250"
          data-ad-slot="7873279252"
          data-ad-format="auto"
          data-full-width-responsive="true"
        />
      ) : (
        <div className="ad-placeholder-5">
          <p>Advertisement 300 x 250</p>
        </div>
      )}
    </div>
  );
};

export default Ad5;