// reactapp/first-app/src/Ad Folder/Ad2.js
import React, { useEffect, useState } from 'react';
import './Ad2.css';

const Ad2 = () => {
  const [isOpen, setIsOpen] = useState(false); // Start with closed window
  const isDevelopment = process.env.NODE_ENV === 'development';

  // Toggles the visibility of the ad
  const toggleAd = () => {
    setIsOpen(!isOpen);
  };

  // Auto-open the ad after 10 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(true);
    }, 10000); // 20 seconds delay

    // Clear timeout on component unmount
    return () => clearTimeout(timer);
  }, []); // Empty dependency array means this runs once on mount

  // Adds ad functionality when the app is not in development mode
  useEffect(() => {
    if (!isDevelopment && isOpen) {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (error) {
        console.error('AdSense error:', error);
      }
    }
  }, [isDevelopment, isOpen]);

  return (
    <>
      {/* Dropdown button to open/close the ad, now positioned on the left top */}
      <div className={`ad-container ${isOpen ? 'open' : 'closed'}`}>
      <button className="ad-toggle" onClick={toggleAd}>
        {isOpen ? '▼' : '▲'}
      </button>
      <div className="ad-content">
        {!isDevelopment ? (
          <ins
            className="adsbygoogle"
            style={{ display: 'inline-block' }}
            data-ad-client="ca-pub-8102084468796250"
            data-ad-slot="7873279252"
            data-ad-format="auto"
            data-full-width-responsive="true"
          />
        ) : (
          <div className="ad-placeholder">
            <p>Advertisement 300 x 250</p>
          </div>
        )}
      </div>
    </div>
  </>
);
};

export default Ad2;