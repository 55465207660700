import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import axios from "axios";
import Papa from "papaparse";

const FlightContext = createContext();

const AIRPORT_CSV_PATH = "/airports.csv"; // Path to the CSV file
const INITIAL_CHUNK_SIZE = 1000;
const CHUNK_LOAD_INTERVAL = 2000; // 2 seconds between chunks

export const FlightProvider = ({ children }) => {
  const [flights, setFlights] = useState([]);
  const [allFlights, setAllFlights] = useState([]);
  const [airportData, setAirportData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [flightDetails, setFlightDetails] = useState([]);
  const [selectedFlightPath, setSelectedFlightPath] = useState([]);
  const [airportDataLoaded, setAirportDataLoaded] = useState(false);
  const [currentChunkIndex, setCurrentChunkIndex] = useState(0);

  // Map specific states
  const [selectedFlight, setSelectedFlight] = useState(null);
  const [totalDistance, setTotalDistance] = useState(0);
  const [coveredDistance, setCoveredDistance] = useState(0);
  const [mapCenter, setMapCenter] = useState([37.0902, -95.7129]); // Default to USA center

  const fetchFlights = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
       `https://flight.matcheclub.com/api/aviation-edge/flights/?limit=7000`
      );
      const allFlightsData = response.data;
      if (Array.isArray(allFlightsData) && airportDataLoaded) {
        const validFlights = allFlightsData.filter(
          (flight) =>
            flight.status === "en-route" &&
            flight.flight?.iataNumber &&
            flight.flight.iataNumber !== "XXD" &&
            getAirportInfo(flight.departure?.iataCode) &&
            getAirportInfo(flight.arrival?.iataCode)
        );
        setAllFlights(validFlights);
        setCurrentChunkIndex(0);
        // Set initial chunk
        setFlights(validFlights.slice(0, INITIAL_CHUNK_SIZE));
      }
    } catch (error) {
      console.error("Error fetching flight data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Progressive loading of flights
  useEffect(() => {
    if (allFlights.length > 0 && currentChunkIndex < allFlights.length) {
      const chunkInterval = setInterval(() => {
        const nextChunkStart = currentChunkIndex;
        const nextChunkEnd = Math.min(nextChunkStart + INITIAL_CHUNK_SIZE, allFlights.length);
        const nextChunk = allFlights.slice(nextChunkStart, nextChunkEnd);
        
        setFlights(prevFlights => [...prevFlights, ...nextChunk]);
        setCurrentChunkIndex(nextChunkEnd);
      }, CHUNK_LOAD_INTERVAL);

      return () => clearInterval(chunkInterval);
    }
  }, [allFlights, currentChunkIndex]);

  const fetchFlightDetails = useCallback(async () => {
    try {
      const response = await axios.get(
      `https://flight.matcheclub.com/api/aviation-edge/timetable/?limit=10000`
      );
      setFlightDetails(response.data);
    } catch (error) {
      console.error("Error fetching flight details:", error);
    }
  }, []);

  const loadCSVData = async () => {
    Papa.parse(AIRPORT_CSV_PATH, {
      download: true,
      header: false,
      complete: (result) => {
        const structuredData = result.data.map((row) => ({
          city: row[0],
          country: row[1],
          depCode: row[2],
          arrCode: row[3],
          airportName: row[4],
          latitude: parseFloat(row[5]),
          longitude: parseFloat(row[6]),
        }));
        setAirportData(structuredData);
        setAirportDataLoaded(true);
      },
      error: (error) => {
        console.error("Error loading CSV:", error);
      },
    });
  };

  const getAirportInfo = (iataCode) => {
    return airportData.find(
      (airport) => airport.depCode === iataCode || airport.arrCode === iataCode
    );
  };

  const showFlightPath = (flight) => {
    if (!flight || !flight.geography) return;

    const { latitude, longitude } = flight.geography;
    const departureAirport = getAirportInfo(flight.departure.iataCode);
    const arrivalAirport = getAirportInfo(flight.arrival.iataCode);

    if (departureAirport && arrivalAirport) {
      const flightPath = [
        [departureAirport.latitude, departureAirport.longitude],
        [latitude, longitude],
        [arrivalAirport.latitude, arrivalAirport.longitude],
      ];
      setSelectedFlightPath(flightPath);
    }
    setSelectedFlight(flight);

    const totalDist = haversineDistance(
      departureAirport?.latitude || 0,
      departureAirport?.longitude || 0,
      arrivalAirport?.latitude || 0,
      arrivalAirport?.longitude || 0
    );

    const coveredDist = haversineDistance(
      departureAirport?.latitude || 0,
      departureAirport?.longitude || 0,
      latitude,
      longitude
    );

    setTotalDistance(totalDist);
    setCoveredDistance(coveredDist);

    setMapCenter([latitude, longitude]); // Update map center to selected flight
  };

  const removeFlightPath = () => {
    setSelectedFlightPath([]);
    setSelectedFlight(null);
  };

  const haversineDistance = (lat1, lon1, lat2, lon2) => {
    const toRad = (value) => (value * Math.PI) / 180;
    const R = 6371; // Earth's radius in kilometers

    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return parseFloat((R * c).toFixed(0)); // Distance in kilometers
  };

  useEffect(() => {
    fetchFlights();
    fetchFlightDetails();
    loadCSVData();
  }, [airportDataLoaded]);

  return (
    <FlightContext.Provider
      value={{
        flights,
        flightDetails,
        loading,
        getAirportInfo,
        showFlightPath,
        removeFlightPath,
        selectedFlight,
        totalDistance,
        coveredDistance,
        mapCenter,
        setSelectedFlight,
      }}
    >
      {children}
    </FlightContext.Provider>
  );
};

export const useFlightAPI = () => {
  return useContext(FlightContext);
};