import React, { useState, useEffect } from "react";
import "./TopAirports.css"; // Import the CSS for styling
import AppCard from "../AppCard";
import loadingGif from '../TrendingFlight/loading1.gif'; // Import the loading GIF (update the path accordingly)

function TopAirports() {
  const [isOpen, setIsOpen] = useState(true);
  const [airports, setAirports] = useState([]); // State to store airport data
  const [error, setError] = useState(null); // Error state
  const [isLoading, setIsLoading] = useState(true); // Loading state
  // const [showAppCard, setShowAppCard] = useState(false);
  
  // Mock airport data
  const mockAirportsData = [
    { iata: "DXB", name: "Dubai International Airport", country: "United Arab Emirates" },
    { iata: "LAX", name: "Los Angeles International Airport", country: "United States" },
    { iata: "HND", name: "Tokyo Haneda Airport", country: "Japan" },
    { iata: "ORD", name: "O'Hare International Airport", country: "United States" },
    { iata: "LHR", name: "Heathrow Airport", country: "United Kingdom" },
    { iata: "HKG", name: "Hong Kong International Airport", country: "Hong Kong" },
    { iata: "PVG", name: "Shanghai Pudong International Airport", country: "China" },
    { iata: "CDG", name: "Charles de Gaulle Airport", country: "France" },
    { iata: "DFW", name: "Dallas/Fort Worth International Airport", country: "United States" },
    { iata: "CAN", name: "Guangzhou Baiyun International Airport", country: "China" },
    { iata: "IST", name: "Istanbul Airport", country: "Turkey" },
    { iata: "FRA", name: "Frankfurt Airport", country: "Germany" },
    { iata: "SIN", name: "Singapore Changi Airport", country: "Singapore" },
    { iata: "MAD", name: "Adolfo Suárez Madrid-Barajas Airport", country: "Spain" },
    { iata: "BCN", name: "Barcelona-El Prat Airport", country: "Spain" },
    { iata: "SFO", name: "San Francisco International Airport", country: "United States" },
    { iata: "JFK", name: "John F. Kennedy International Airport", country: "United States" },
    { iata: "AMS", name: "Amsterdam Airport Schiphol", country: "Netherlands" },
    { iata: "YYZ", name: "Toronto Pearson International Airport", country: "Canada" },
    { iata: "MUC", name: "Munich Airport", country: "Germany" },
    { iata: "SYD", name: "Sydney Kingsford Smith Airport", country: "Australia" }
  ];

  // Set mock airports data in the state
  // useEffect(() => {
  //   const checkScreenHeight = () => {
  //     if (window.innerHeight < 1000) { // If screen height is less than 100vh, hide AppCard
  //       setShowAppCard(false);
  //     } else {
  //       setShowAppCard(true);
  //     }
  //   };

  //   checkScreenHeight();
  //   window.addEventListener('resize', checkScreenHeight);

  //   return () => {
  //     window.removeEventListener('resize', checkScreenHeight);
  //   };
  // }, []);

  useEffect(() => {
    // Simulate a data fetching delay
    setTimeout(() => {
      const filteredAirports = mockAirportsData.filter(airport => 
        topAirportsIATA.includes(airport.iata)
      );
      setAirports(filteredAirports);
      setIsLoading(false); // Set loading to false after data is fetched
    }, 1000); // Simulated delay of 1 second
  }, []); // Empty dependency array to run only once

  const toggleCard = () => {
    setIsOpen(!isOpen);
  };

  const topAirportsIATA = [
    "DXB", "LAX", "HND", "ORD", "LHR", "HKG",
    "PVG", "CDG", "DFW", "CAN", "IST", "FRA",
    "SIN", "MAD", "BCN", "SFO", "JFK", "AMS",
    "YYZ", "MUC", "SYD"
  ];

  return (
    <div className="card1">
      <div className="card-header1" onClick={toggleCard}>
        <span className={`top-wrapper-heading ${isOpen ? "bold-heading" : ""}`}>
          Top Airports
        </span>
        <button className="toggle-button1">
          {isOpen ? (
            <img className="trending-icon1" src="/assets/uplist-black.png" alt="Collapse" />
          ) : (
            <img className="trending-icon1" src="/assets/downlist-black.png" alt="Expand" />
          )}
        </button>
      </div>

      {isOpen && (
        <div className="card-body1">
          {error ? (
            <div className="error-message">{`Error: ${error}`}</div>
          ) : (
            <ul className="airport-list1">
              {isLoading ? (
                <div className="loading-animation">
                  <img src={loadingGif} alt="Loading..." height={150} width={150} />
                </div>
              ) : (
                airports.map((airport) => (
                  <li key={airport.iata} className="airport-item1">
                    <div className="airport-info1">
                      <span className="airport-rank1">
                        {airport.name}, ({airport.iata})
                      </span>
                      <span className="airport-name1">{airport.country}</span>
                    </div>
                  </li>
                ))
              )}
            </ul>
          )}
        </div>
      )}
    </div>
  );
}

export default TopAirports;