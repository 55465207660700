import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import Map, {
  Marker,
  Popup,
  NavigationControl,
  GeolocateControl,
  FullscreenControl,
  Source,
  Layer,
} from "react-map-gl";
import { useFlightAPI } from "../../Api File/FlightApi"; // Updated import
import FlightCard from "../Flight Info Card/FlightCard";
import "./Map.css";
import FlightSearch from "../../FlightSearch";
import "mapbox-gl/dist/mapbox-gl.css";
import MapControls from "./MapControls";
import Ad2 from "../../../Ad Folder/Ad2";

const FlightMarker = React.memo(({ flight, isSelected, onClick }) => {
  const currentPos = [
    flight.geography?.latitude || 0,
    flight.geography?.longitude || 0,
  ];

  return (
    <Marker
      longitude={currentPos[1]}
      latitude={currentPos[0]}
      onClick={onClick}
      anchor="center"
    >
      <div
        style={{
          transform: isSelected ? "scale(1.5)" : "scale(1.5)",
          transition: "transform 0.1s",
          cursor: "pointer",
        }}
      >
        <img
          src={`/assets/${isSelected ? "airplan-red.png" : "airportImg.png"}`}
          alt="Flight icon"
          style={{ width: "30px" }}
        />
      </div>
      {isSelected && (
        <Popup
          longitude={currentPos[1]}
          latitude={currentPos[0]}
          closeButton={false}
        >
          Flight No: {flight.flight?.iataNumber || "N/A"}
        </Popup>
      )}
    </Marker>
  );
});

const MapComponent = () => {
  const {
    flights,
    flightDetails,
    loading,
    getAirportInfo,
    showFlightPath,
    removeFlightPath,
    selectedFlight,
    totalDistance,
    coveredDistance,
    mapCenter,
    setSelectedFlight,
  } = useFlightAPI(); // Using context

  const mapRef = useRef(); // Reference to the map
  const [controlsVisible, setControlsVisible] = useState(true);
  const [isFlightsVisible, setIsFlightsVisible] = useState(true);
  const [mapReady, setMapReady] = useState(false);
  const [showAirportCard, setShowAirportCard] = useState(false); // New state variable
  const [showAirlineCard, setShowAirlineCard] = useState(false);
  const toggleFlights = () => {
    setIsFlightsVisible(true); // Ensure flights are visible
    if (mapRef.current) {
      mapRef.current.flyTo({
        zoom: 5,
        speed: 1, // Animation speed
        curve: 1, // Animation curve
      });
    }
  };

  // Update the map's zoom level to control flight visibility
  useEffect(() => {
    const handleZoomChange = () => {
      if (mapRef.current) {
        const zoom = mapRef.current.getZoom();
        setIsFlightsVisible(zoom > 4);
      }
    };

    if (mapRef.current) {
      mapRef.current.on('zoomend', handleZoomChange);
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.off('zoomend', handleZoomChange);
      }
    };
  }, [mapReady]);

  const handleFlightClick = useCallback(
    (flight) => {
      if (!flight) {
        handleCardClose(); // Close the flight card if flight is null
        return;
      }

      // Check for valid flight data
      if (!flight.geography || !flight.geography.latitude || !flight.geography.longitude) {
        console.error("Invalid flight data:", flight);
        return;
      }

      // Hide the airport card when a flight is selected
      setShowAirportCard(false);
      setShowAirlineCard(false)
      // Show flight path and set selected flight
      showFlightPath(flight);
      setSelectedFlight(flight); // Set the selected flight
    },
    [showFlightPath]
  );

  const handleCardClose = useCallback(() => {
    removeFlightPath();
  }, [removeFlightPath]);

  const getFlightDetails = useCallback(
    (flight) => {
      const flightNumber = flight?.flight?.number;
      const departureIataCode = flight?.departure?.iataCode;
      const arrivalIataCode = flight?.arrival?.iataCode;

      return (
        flightDetails.find(
          (detail) =>
            detail.flight?.number === flightNumber ||
            detail.departure?.iataCode === departureIataCode ||
            detail.arrival?.iataCode === arrivalIataCode
        ) || {}
      );
    },
    [flightDetails]
  );

  const trendingFlightsInfo = useMemo(() => {
    return flights.map((flight) => {
      const departureCountry = getAirportInfo(flight.departure?.iataCode)?.country || "N/A";
      const arrivalCountry = getAirportInfo(flight.arrival?.iataCode)?.country || "N/A";
      return {
        ...flight,
        departureCountry,
        arrivalCountry,
      };
    });
  }, [flights, getAirportInfo]);

  const getRouteCoordinates = (flight) => {
    const departureInfo = getAirportInfo(flight?.departure?.iataCode);
    const arrivalInfo = getAirportInfo(flight?.arrival?.iataCode);

    if (!departureInfo || !arrivalInfo) return [];

    return [
      [departureInfo.latitude, departureInfo.longitude],
      [flight.geography.latitude, flight.geography.longitude],
      [arrivalInfo.latitude, arrivalInfo.longitude],
    ];
  };

  const routeCoordinates = useMemo(() => {
    return selectedFlight && getRouteCoordinates(selectedFlight);
  }, [selectedFlight]);

  useEffect(() => {
    // Automatically set the map center when the selected flight changes
    if (selectedFlight && mapReady) {
      const currentPos = [
        selectedFlight.geography.longitude || 0, // Longitude first for correct order
        selectedFlight.geography.latitude || 0,  // Latitude second for correct order
      ];

      console.log("Centering map on flight at position:", currentPos);
      if (mapRef.current) {
        mapRef.current.flyTo({
          center: currentPos, // [longitude, latitude]
          zoom: 6, // Adjust zoom level as necessary
          speed: 1, // Adjust speed of animation (1 is normal speed, adjust as needed)
          curve: 1, // Adjust the curve of the flight (1 is a smooth curve)
          easing: (t) => t, // Easing function for animation, can customize for different effects
        });
      }
    }
  }, [selectedFlight, mapReady]);

  return (
    <>
      <Map
        ref={mapRef}
        initialViewState={{
          latitude: mapCenter[0],
          longitude: mapCenter[1],
          zoom: 5,
        }}
        style={{ width: "100%", height: "100vh" }}
        minZoom={4}
        onLoad={() => setMapReady(true)}
        mapboxAccessToken={
          "pk.eyJ1IjoiaXVjc3RlY2hub2xvZ2llcyIsImEiOiJjbTJkMGN6YWoxODhoMm1zZjQxcTc5MDBiIn0.c1BtxDo0mR6oDo2yW2Vhaw"
        }
        mapStyle="mapbox://styles/mapbox/outdoors-v12"
      >
        <NavigationControl position="top-left" />
        <GeolocateControl position="top-left" />
        <FullscreenControl />

        {!loading && !mapReady ? (
          <div className="loading">Loading map and flights...</div>
        ) : (
          flights.map(
            (flight, index) =>
              (isFlightsVisible ||
                selectedFlight?.flight?.iataNumber ===
                  flight.flight?.iataNumber) && (
                <FlightMarker
                  key={`${flight.flight?.icaoNumber || flight.flight?.number}-${index}-${flight.geography?.latitude}-${flight.geography?.longitude}`}
                  flight={flight}
                  isSelected={
                    selectedFlight?.flight?.iataNumber ===
                    flight.flight?.iataNumber
                  }
                  onClick={() => handleFlightClick(flight)}
                />
              )
          )
        )}

        {routeCoordinates && (
          <>
            <Source
              type="geojson"
              data={{
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: routeCoordinates.map((coord) => [
                    coord[1],
                    coord[0],
                  ]),
                },
              }}
            >
              <Layer
                id="lineLayer"
                type="line"
                layout={{
                  "line-cap": "round",
                  "line-join": "round",
                }}
                paint={{
                  "line-color": "#9a5af9",
                  "line-width": 4,
                }}
              />
            </Source>

            <Marker
              longitude={routeCoordinates[0][1]}
              latitude={routeCoordinates[0][0]}
              anchor="center"
            >
              <img
                src="/assets/airportMarker1.png"
                alt="Departure Airport Icon"
                style={{ width: "35px" }}
              />
            </Marker>

            <Marker
              longitude={routeCoordinates[2][1]}
              latitude={routeCoordinates[2][0]}
              anchor="center"
            >
              <img
                src="/assets/airportMarker.png"
                alt="Arrival Airport Icon"
                style={{ width: "35px" }}
              />
            </Marker>
          </>
        )}

        {/* {controlsVisible && ( */}
          <MapControls
            map={mapRef.current}
            isFlightsVisible={isFlightsVisible}
            toggleFlights={toggleFlights}
          />
        {/* )} */}
      </Map>
      <Ad2/>

      {selectedFlight && (
        <FlightCard
          flightInfo={selectedFlight}
          flightDetails={getFlightDetails(selectedFlight)}
          departureInfo={getAirportInfo(selectedFlight?.departure?.iataCode)}
          arrivalInfo={getAirportInfo(selectedFlight?.arrival?.iataCode)}
          totalDistance={totalDistance}
          coveredDistance={coveredDistance}
          onClose={handleCardClose}
        />
      )}

      <FlightSearch
        onFlightSelect={(flight) => {
          handleFlightClick(flight);
          if (flight) {
            setShowAirportCard(false); // Hide airport card when flight is selected
            setShowAirlineCard(false)
          }
        }}
        flights={trendingFlightsInfo}
        onSearchFocus={() => setControlsVisible(false)}
        onSearchBlur={() => setControlsVisible(true)}
        selectedFlight={selectedFlight}
        showAirportCard={showAirportCard} // Pass this state
        setShowAirportCard={setShowAirportCard} // Allow controlling from FlightSearch
        showAirlineCard={showAirlineCard}
        setShowAirlineCard={setShowAirlineCard}
      />

    </>
  );
};

export default MapComponent;