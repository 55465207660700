import React, { useEffect, useState } from 'react';
import axios from 'axios';
import loadingGif from '../../TrendingFlight/loading1.gif'; // Adjust path as necessary
import './AirportCard.css';
import AppCard from '../../AppCard';
import Ad4 from '../../../Ad Folder/Ad4';

const AirportCard = ({ airportCode, onClose, setShowAirlineCard }) => {
    const [activeTab, setActiveTab] = useState('departures');
    const [arrivals, setArrivals] = useState([]);
    const [departures, setDepartures] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    // const [showAppCard, setShowAppCard] = useState(false);

    // useEffect(() => {
    //     const checkScreenHeight = () => {
    //         if (window.innerHeight < 1000) { // If screen height is less than 100vh, hide AppCard
    //             setShowAppCard(false);
    //         } else {
    //             setShowAppCard(true);
    //         }
    //     };

    //     checkScreenHeight();
    //     window.addEventListener('resize', checkScreenHeight);

    //     return () => {
    //         window.removeEventListener('resize', checkScreenHeight);
    //     };
    // }, []);

    // Helper function to convert timestamp to a readable date-time format
    const formatTime = (timestamp) => {
        if (!timestamp) return 'N/A';
        const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
        return date.toLocaleString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        });
    };

    // Fetch data from the API
    const fetchFlightData = async (mode) => {
        try {
            const response = await axios.get(
                `https://flight.matcheclub.com/api/airport-schedule/`,
                {
                    params: {
                        code: airportCode,
                        mode: mode,
                        limit: 100,
                        page: 1,
                    },
                    headers: {
                        accept: 'application/json',
                        'X-CSRFTOKEN': 'sQTG09i2ATNY7uJp07j5j5lVIzZhlbDE',
                    },
                }
            );
            return response.data.result.response.airport.pluginData.schedule[mode].data || [];
        } catch (error) {
            console.error(`Error fetching ${mode} data:`, error);
            setError('Failed to fetch flight data.');
            return [];
        }
    };

    useEffect(() => {
        const getFlightDetails = async () => {
            setLoading(true);
            setError(''); // Reset error state
            const arrivalData = await fetchFlightData('arrivals');
            const departureData = await fetchFlightData('departures');
            setArrivals(arrivalData);
            setDepartures(departureData);
            setLoading(false);
        };

        if (airportCode) {
            getFlightDetails();
        }
    }, [airportCode]);

    const flights = activeTab === 'departures' ? departures : arrivals;

    return (
        <div className="airport-card">
            {/* Card Header */}
            <div className="airport-card-header">
                <span className="airport-heading">{airportCode} Airport Schedule</span>
                <button className="close-btn" onClick={onClose}>
                    <img src="/assets/close.png" alt="Close" />
                </button>
            </div>

            {/* Tab Navigation */}
            <div className="tab-buttons">
                <button
                    className={`tab-button ${activeTab === 'departures' ? 'active' : ''}`}
                    onClick={() => setActiveTab('departures')}
                >
                    <span className='airport-filter-btn'>Departures</span>
                </button>
                <button
                    className={`tab-button ${activeTab === 'arrivals' ? 'active' : ''}`}
                    onClick={() => setActiveTab('arrivals')}
                >
                    <span className='airport-filter-btn'>Arrivals</span>
                </button>
            </div>

            {/* Flight List */}
            <div className="airport-list">
                {loading ? (
                    <div className="loading">
                    <div className="loading-animation" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                        <img src={loadingGif} alt="Loading..." height={150} width={150} />
                    </div>
                    </div>
                ) : error ? (
                    <p className="error-message">{error}</p>
                ) : flights.length > 0 ? (
                    <ul className="airport-ul">
                        {flights.map((flight, index) => (
                            <>
                                <li key={index} className="airport-item">
                                    <div className="airport-info">
                                        <div className="airport-left">
                                            {/* Flight Number */}
                                            {/* Display Departure or Arrival City based on Tab */}
                                            <div className="airport-route">
                                                {activeTab === 'departures' ? (
                                                    <>
                                                        <div className="airport-name">
                                                            <div>
                                                                <span className='airport-card-flight-no '>Flight No:</span>
                                                                <span className='airport-card-flight-value '>
                                                                    {flight.flight.identification.number.default || 'N/A'}</span>
                                                            </div>
                                                            <span className='airport-card-flight-city'>
                                                                {flight.flight.airport.destination?.position?.region?.city || 'N/A'}
                                                            </span>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="airport-name">
                                                            <div>
                                                                <span className='airport-card-flight-no '>Flight No:</span>
                                                                <span className='airport-card-flight-value '>
                                                                    {flight.flight.identification.number.default || 'N/A'}</span>
                                                            </div>
                                                            <div>
                                                            <span className='airport-card-flight-city'>
                                                                {flight.flight.airport.origin?.position?.region?.city || 'N/A'}
                                                            </span>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                        {/* Scheduled Departure or Arrival Time */}
                                        <div className="airport-time">
                                            {activeTab === 'departures' ? (
                                                <>
                                                    <span className='time'>{formatTime(flight.flight.time.scheduled.departure)}</span>
                                                    <span className='time'>{formatTime(flight.flight.time.scheduled.arrival)}</span>
                                                </>
                                            ) : (
                                                <>
                                                    <span className='time'>{formatTime(flight.flight.time.scheduled.departure)}</span>
                                                    <span className='time'>{formatTime(flight.flight.time.scheduled.arrival)}</span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </li>
                                {index === 1 && (
                                    <div className="airport-ad4-container">
                                        <Ad4 />
                                    </div>
                                )}
                            </>
                        ))}
                    </ul>
                ) : (
                    
                    <div className="flight-list-airline"><p> No flights available </p> </div>
                )}
            </div>

            <div className="airport-card-bottom">
                 <AppCard />
            </div>
        </div>
    );
};

export default AirportCard;